.app__about {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__profiles {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.app__profile-item {
    width: 400px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem; 

    img {
        width: 100%;
        height: 300px;
        border-radius: 15px;
        object-fit: cover; 
    }

    @media screen and (max-width: 800px) {
        width: 240px;
        margin: 1rem 2rem; 
        img {
            height: 220px
        }
    }

    @media screen and (min-width: 2000px) {
        width: 540px;
        margin: 2rem 4rem; 
        img {
            height: 500px
        }
    }
}
/* Card container */
.card-container {
    display: flex;
    justify-content: center;
    flex-direction: column; /* Change from 'row' to 'column' */
    align-items: center;
    min-height: 100vh;
    background-color: #f0f0f0; /* Set your desired background color */
}
  
.back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: #ffffff;
    padding: 10px 20px;
    border: 1px solid #000000;
    cursor: pointer;

    &:hover {
      background-color: #000000;
      color: #ffffff;
    }
  }


/* Card */
.card {
    background-color: #fff; /* White background */
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%; /* Adjust the width as needed */
    max-width: 1200px; /* Adjust the maximum width as needed */
    padding: 1rem;
    margin: 1rem;
}
  
/* Card image */
.card-img {
    width: 100%;
    min-height:500px;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}
  
/* Card content */
.card-content {
    padding: 1rem;
}
  
/* Card title */
.card-title {
    font-size: 1.25rem;
    font-weight: 600;


    @media screen and (min-width:800px) {
        font-size: 2rem;
        font-weight: 700;
    }

    @media screen and (min-width:1200px) {
        font-size: 2.5rem;
        font-weight: 800;
    }
}
  
  /* Card subtitle */
.card-subtitle {
    color: #2e2929; /* Adjust the color as needed */
}